import QuizResultStyle from '../less/quizresult.module.less';
import Facebook from '../images/facebook-filled.png';
import Twitter from '../images/twitter-filled.png';
import SpeechBubble from '../images/speechbubble.svg'
import Check from '../images/check.svg'
import { Button, Card, Progress } from 'antd';
import { FormatNumberToLocaleString } from '../helpers/numberFormat';
import * as React from 'react';
import useIntl from '../helpers/useIntl';

const QuizResult = (props) => {
  const { questions, colors, score, restartFn, location, slug } = props;
  const intl = useIntl();

  const getResultTitle = () => {
    if (score / questions.length <= 0.5) {
      return intl.formatMessage({ id: 'quiz_result3_title' });
    }

    if (score / questions.length < 0.8) {
      return intl.formatMessage({ id: 'quiz_result2_title' });
    }

    if ((score / questions.length) >= 0.9) {
      return intl.formatMessage({ id: 'quiz_result1_title' });
    }
  };

  const sharePath = location.href.replace(/\/?$/, '/');

  return (
    <div className={QuizResultStyle.wrapper}>
      <section className={QuizResultStyle.summary}>
        <h2
          className={"visually-hidden"}> {intl.formatMessage(
          { id: 'quiz_results_heading' })}</h2>
        {/* Leave this here for SEO purposes */}
        <div className={QuizResultStyle.decorationDrops}/>
        <div className={QuizResultStyle.content}>
          <div>
            <h2 className={QuizResultStyle.title}>
              {getResultTitle()}
            </h2>
            <h3 className={QuizResultStyle.subtitle}>
              {intl.formatMessage({ id: 'quiz_result_subtitle' })}
            </h3>
          </div>
          <span
            className={QuizResultStyle.score}>{`${score}/${questions.length}`}</span>
          <div>
            <div className={QuizResultStyle.share}>
              {intl.formatMessage({ id: 'quiz_result_share' })}
              <div style={{ marginTop: '15px' }}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${sharePath}${score}`}>
                  <img src={Facebook}
                       srcSet={`${Facebook} 2x`}
                       style={{ marginRight: '15px' }}
                       alt="Facebook"/>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://twitter.com/intent/tweet?url=${sharePath}${score}`}>
                  <img src={Twitter}
                       srcSet={`${Twitter} 2x`}
                       style={{ marginRight: '15px' }}
                       alt="Twitter"/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={QuizResultStyle.decorationDrops}/>
      </section>

      <section className={QuizResultStyle.reflection}>
        <h3>{intl.formatMessage({ id: 'quiz_result_compare_title' })}</h3>
        <span className={QuizResultStyle.subtitle}>
          {intl.formatMessage({ id: 'quiz_result_compare_subtitle' })}
          </span>
        <div className={QuizResultStyle.legend}>
          <div className={QuizResultStyle.iconGroup}>
            <img src={SpeechBubble}
                 srcSet={`${SpeechBubble} 2x`}
                 style={{ width: '28px', marginRight: '10px' }}
                 alt=""/>
          <span className={QuizResultStyle.own}>
          {intl.formatMessage({ id: 'quiz_result_compare_own' })}
          </span>
          </div>
          <div className={QuizResultStyle.iconGroup}>
            <img src={Check}
                 srcSet={`${Check} 2x`}
                 style={{ width: '30px', marginRight: '10px' }}
                 alt=""/>
          <span className={QuizResultStyle.correct}>
          {intl.formatMessage({ id: 'quiz_result_compare_right' })}
          </span>
          </div>
        </div>
        <div className={QuizResultStyle.answerGrid}>
          {questions.map((question, i) => (
            <div className={QuizResultStyle.answer}>
                    <span
                      className={QuizResultStyle.answerBody}><b>{i + 1 + '. '}</b>
                      <span style={{ display: 'inline' }}
                            dangerouslySetInnerHTML={{
                              __html: intl.translate(question, 'body'),
                            }}/>
                    </span>
              <Card>
                <div className={QuizResultStyle.label}>
                  <img src={SpeechBubble}
                       srcSet={`${SpeechBubble} 2x`}
                       style={{ width: '28px', height: '28px', marginRight: '10px' }}
                       alt=""/>
                  <Progress
                    percent={Math.round(
                      ((question.userAnswer / question.max_amount) * 100) * 100) / 100}
                    showInfo={false}
                    strokeColor={colors.own}/>
                  <div>
                    <span
                      className={QuizResultStyle.hidden}>{intl.formatMessage({ id: 'quiz_result_compare_own' })}</span>
                    <b
                      style={{ fontSize: '20px' }}>{FormatNumberToLocaleString(
                      question.userAnswer)}</b> /
                    € {FormatNumberToLocaleString(question.max_amount)}
                  </div>
                </div>
                <div className={QuizResultStyle.label}>
                  <img src={Check}
                       srcSet={`${Check} 2x`}
                       style={{ width: '30px', marginRight: '10px' }}
                       alt=""/>
                  <Progress
                    percent={Math.round(
                      ((question.answer / question.max_amount) * 100) * 100) / 100}
                    showInfo={false}
                    strokeColor={colors.other}/>
                  <div>
                    <span
                      className={QuizResultStyle.hidden}>{intl.formatMessage({ id: 'quiz_result_compare_right' })}</span>
                    <b style={{ fontSize: '20px' }}>{FormatNumberToLocaleString(
                      question.answer)}</b> / € {FormatNumberToLocaleString(
                    question.max_amount)}
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>
      </section>
      <div className={QuizResultStyle.controls}>
        <Button type={'primary'} onClick={() => restartFn()}>
          {intl.formatMessage({ id: 'quiz_again' })}
        </Button>
      </div>
    </div>
  );
};

export default QuizResult;
