import * as React from 'react';
import {useState} from 'react';
import {Button, Slider} from 'antd';
import QuizQuestionStyle from '../../less/quiz-question.module.less';
import QuizStyle from '../../less/quiz.module.less';
import useIntl from '../../helpers/useIntl';
import Img from 'gatsby-image';
import PropTypes from "prop-types";
import {FormatNumberToLocaleString} from "../../helpers/numberFormat";

const QuizQuestion = ({
                        isLastQuestion,
                        question,
                        image,
                        imageAltText,
                        min,
                        max,
                        step,
                        next
                      }) => {
  const intl = useIntl();
  const [value, setValue] = useState(min);

  let nextButtonCopy;
  if (!isLastQuestion) {
    nextButtonCopy = intl.formatMessage({id: 'quiz_next-question'})
  } else {
    nextButtonCopy = intl.formatMessage({id: 'quiz_final_question'})
  }

  // hack the ant-slider to set the aria-labelledby attribute
  setTimeout(() => {
    const f = document.getElementsByClassName('ant-slider-handle')
    f[0].setAttribute('aria-labelledby', 'question')
  })

  const marks = {}
  marks[min] = {
    style: {transform: 'translateX(0)'},
    label: '€ ' + FormatNumberToLocaleString(min),
  }
  marks[max] = {
    style: {left: 'initial', right: '0', transform: 'translateX(0)'},
    label: '€ ' + FormatNumberToLocaleString(max),
  }

  return (
    <div>
      <div className={QuizQuestionStyle.block}>
        <div className={QuizQuestionStyle.headerImage}>
          {image && image.childImageSharp ? (
            <Img className={QuizQuestionStyle.image}
                 style={{height: '100%'}}
                 fluid={image.childImageSharp.fluid} alt={imageAltText}/>
          ) : ''}
          <span
            className={QuizQuestionStyle.question + ' ' + 'h3'}>
            <div id="question" dangerouslySetInnerHTML={{__html: question}}/>
          </span>
        </div>

        <p
          className={QuizQuestionStyle.sliderInfo}>{intl.formatMessage({id: 'quiz_sliderinfo'})}</p>
        <div>
          <Slider
            className={QuizQuestionStyle.slider + ' slider'}
            marks={marks}
            value={value}
            step={step}
            onChange={value => setValue(value)}
            tooltipVisible={true}
            tipFormatter={(value) => '€ ' + FormatNumberToLocaleString(value)}
            max={max}
            min={min}
          />
        </div>
      </div>
      <div className={QuizStyle.next}>
        <Button
          color="#00CDB4"
          onClick={() => {
            next(value);
            setValue(0);
            // put the focus on the next slider
            document.querySelector('.ant-slider-handle').focus();
          }}
          type="primary"
        >
          {nextButtonCopy}
        </Button>
      </div>
    </div>
  );
};

QuizQuestion.propTypes = {
  question: PropTypes.string,
  image: PropTypes.object,
  imageAltText: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  next: PropTypes.func,
};

QuizQuestion.defaultProps = {
  imageAltText: '',
};

export default QuizQuestion;
