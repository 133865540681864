import useIntl from "./useIntl"

export default function FormatNumberToBillions(amount) {
  const intl = useIntl();
  const ONE_BILLION = 1000000000;
  let formattedString = `${amount} ${intl.formatMessage({id: "general_euro"})}`;

  if (amount > ONE_BILLION - 1) {
    const formattedNumber = (Math.round((amount / ONE_BILLION) * 10) / 10).toLocaleString("nl-BE");
    formattedString = `€ ${formattedNumber} ${formattedNumber > 1 ? intl.formatMessage({id: "general_billions"}) : intl.formatMessage({id: "general_billion"})}`
  }

  return formattedString
}

export function FormatNumberToPercentage(amount, total) {
  return Math.round((amount / total * 100) * 10) / 10;
}

export function FormatNumberToLocaleString(amount) {
  return (Math.round(amount)).toLocaleString("nl-BE");
}
