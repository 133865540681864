import * as React from "react"
import {useState} from "react"
import Layout from "../components/general/layout"
import Container from "../components/general/container"
import QuizQuestion from "../components/quiz/quiz-question"
import {Button} from "antd"
import useIntl from "../helpers/useIntl"
import QuizStyle from "../less/quiz.module.less"
import QuizImage from "../images/quiz.png"
import {defineMessages} from "react-intl"
import {graphql} from 'gatsby'
import QuizResult from "./quiz-result";
import Helmet from "react-helmet";
import Share1 from "../images/share/1.png"
import Share2 from "../images/share/2.png"
import Share3 from "../images/share/3.png"
import Share4 from "../images/share/4.png"
import Share5 from "../images/share/5.png"
import Share6 from "../images/share/6.png"
import Share7 from "../images/share/7.png"
import Share8 from "../images/share/8.png"
import Share9 from "../images/share/9.png"
import Share10 from "../images/share/10.png"

const Quiz = (props) => {
  const {data, location, pageContext} = props;
  const intl = useIntl()
  const [currentIndex, setCurrentIndex] = useState(null)
  const [questions, setQuestions] = useState(
    data.budgetQuiz.fields.budgetQuizItems)
  const colors = {
    own: "#00CDB4",
    other: "#FDA83D",
  }

  let currentQuestion = questions[currentIndex]
  let quizStatus = getStatus();
  const shareImages = [
    Share1,
    Share2,
    Share3,
    Share4,
    Share5,
    Share6,
    Share7,
    Share8,
    Share9,
    Share10,
  ]

  function getStatus() {
    if (currentIndex !== null && !currentQuestion) {
      return 'after'
    }

    if (currentIndex !== null && currentQuestion) {
      return 'during'
    }

    return 'before'
  }


  const nextQuestion = answer => {
    // save value
    setQuestions([
      ...questions.map((question, index) => {
        return index === currentIndex
          ? {
            ...question,
            userAnswer: answer,
          }
          : question
      }),
    ])

    setCurrentIndex(currentIndex + 1)
  }

  let correctAnswers =
    questions.reduce((acc, cur) => {
      let diffMin = Math.abs(cur.min_amount - cur.userAnswer)
      let diffMax = Math.abs(cur.max_amount - cur.userAnswer)
      let diffAnswer = Math.abs(cur.answer - cur.userAnswer)
      let biggestDiff = Math.max(diffMin, diffMax)
      let score = 1 - (diffAnswer / biggestDiff)

      return acc + score
    }, 0)

  correctAnswers = Math.round(correctAnswers);

  const getTitle = () => {
    if (currentIndex === null) {
      return intl.formatMessage({id: "quiz_start_header_title"})
    }

    if (currentQuestion) {
      const translatedMessage = defineMessages({
        id: "quiz_header_progress",
      })
      return intl.formatMessage(translatedMessage, {
        x: currentIndex + 1,
        y: questions.length,
      })
    }

    if (currentIndex && !currentQuestion) {
      return intl.formatMessage({id: "quiz_result_header_title"})
    }
  }

  const restartQuiz = () => {
    setCurrentIndex(null)

    if (typeof window !== "undefined") {
      window.scrollTo(0, 0)
    }
  }

  function importAll(r) {
    return r.keys().map(r);
  }

  const getMetaTags = () => {
    const ogMetaTagImage = pageContext.score ? {
      property: 'og:image',
      content: shareImages[pageContext.score - 1]
    } : {};

    if (pageContext.score <= 5) {
      return [
        ogMetaTagImage,
        {
          property: 'og:title',
          content: intl.formatMessage({id: "quiz_result_share_og_title3"})
        },
        {
          property: 'og:description',
          content: intl.formatMessage({id: "quiz_result_share_og_description3"})
        },
      ]
    }

    if (pageContext.score < 8) {
      return [
        ogMetaTagImage,
        {
          property: 'og:title',
          content: intl.formatMessage({id: "quiz_result_share_og_title2"})
        },
        {
          property: 'og:description',
          content: intl.formatMessage({id: "quiz_result_share_og_description2"})
        },
      ]
    }

    if (pageContext.score >= 9) {
      return [
        ogMetaTagImage,
        {
          property: 'og:title',
          content: intl.formatMessage({id: "quiz_result_share_og_title1"})
        },
        {
          property: 'og:description',
          content: intl.formatMessage({id: "quiz_result_share_og_description1"})
        },
      ]
    }
  }

  return (
    <Layout
      heroBgColor={"#DFECF0"}
      heroTitle={getTitle()}
      heroTextColor={"#142A7F"}
      heroSize={"small"}
    >
      <Helmet meta={getMetaTags()}/>

      <Container size={"small"}>
        {/* BEFORE */}
        {quizStatus === 'before' ? (
          <section className={QuizStyle.start}>
            <img className={QuizStyle.quizImage} src={QuizImage} alt=""/>
            <p style={{margin: "0"}}>
              {intl.formatMessage({id: "quiz_start_description"})}
            </p>
            <Button
              color={colors.own}
              className={QuizStyle.next}
              onClick={() => setCurrentIndex(0)}
              type="primary"
            >
              {intl.formatMessage({id: "quiz_start_button"})}
            </Button>
          </section>
        ) : ""}

        {/* DURING */}
        {quizStatus === 'during' ? (
          <QuizQuestion
            index={currentQuestion.id}
            question={intl.translate(currentQuestion, "body")}
            max={currentQuestion.max_amount}
            step={currentQuestion.step_amount}
            min={currentQuestion.min_amount}
            image={currentQuestion.featuredImg}
            imageAltText={""}
            isLastQuestion={currentIndex === questions.length - 1}
            next={value => nextQuestion(value)}
          />
        ) : ""}

        {quizStatus === 'after' ? (
          <QuizResult
            slug={pageContext.slug}
            location={location}
            questions={questions}
            score={correctAnswers}
            colors={colors}
            restartFn={restartQuiz}
          />
        ) : ""}

      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    budgetQuiz(slug: { eq: $slug }) {
      body_fr
      body_nl
      fields {
        budgetQuizItems {
          answer
          body_fr
          body_nl
          id
          max_amount
          min_amount
          order
          step_amount
          featuredImg {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default Quiz
